




























import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { app_routes } from "@/router/app_routes";
import EdsComponent from "@/components/Eds/EdsComponent.vue";
import Modal from "@/components/UI/Modal.vue";
import { SignResultType } from "@/types/API/SignInfoType";
import { ObjectOf } from "@/types/ObjectOf";

@Component({
  components: {
    EdsComponent,
    Modal,
  },
})
export default class SignExamDataModal extends Vue {
  @Prop({ type: String, required: true }) examId!: number;
  @Prop({ type: String, required: true }) sendSignFn!: (params: {
    id: number;
    sign: string;
  }) => Promise<SignResultType>;
  @Prop({ type: Function, required: true })
  getUserInfo!: () => Promise<void>;
  @Prop({ type: Function, required: true })
  getSignInfoFn!: (id: number) => Promise<void>;

  public signErrors: ObjectOf<string[]> = {};
  public showErrorsModal = false;

  public async edsSignData(): Promise<void> {
    try {
      const data = await this.getSignInfoFn(this.examId);

      const sign = await window.eds.sign(JSON.stringify(data), {
        asBase64String: true,
      });

      const signResult: SignResultType = await this.sendSignFn({
        id: this.examId,
        sign,
      });

      if (signResult.errors !== undefined) {
        this.signErrors = signResult.errors || {};
        this.showErrorsModal = true;
      } else {
        await this.getUserInfo();
        await this.$router.push({
          path: `${app_routes.exam_result.slug}/${this.examId}`,
          query: { preview: "true" },
        });
      }
    } catch {
      await this.$router.push({
        path: app_routes.user_home.path,
      });
    }
  }

  @Emit()
  public close(): void {
    return;
  }
}
