

















































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { Route } from "vue-router";
import Loader from "@/components/UI/Loader.vue";
import Timer from "@/components/UI/Timer.vue";
import RadioGroupField from "@/components/form/RadioGroupField.vue";
import Modal from "@/components/UI/Modal.vue";
import SignExamDataModal from "@/components/Eds/SignExamDataModal.vue";
import {
  USER_MANAGER_MODULE,
  USER_PROCESSING_EXAM,
} from "@/constants/storeNames";
import { ExamType } from "@/types/API/ExamType";
import { ObjectOf } from "@/types/ObjectOf";
import { app_routes } from "@/router/app_routes";
import { ExamQuestionType } from "@/types/API/ExamQuestionType";
import { ExamAnswerType } from "@/types/API/AnswersTypes";
import { SelectOptionType } from "@/types/form/SelectOptionType";
import { AnswerValueType } from "@/types/API/AnswersTypes";
import { SignInfoType, SignResultType } from "@/types/API/SignInfoType";
import { STATUS_IN_PROGRESS } from "@/constants/testStatuses";

const userManager = namespace(USER_MANAGER_MODULE);
const processingExam = namespace(USER_PROCESSING_EXAM);
const DEFAULT_VALUE = 0;
const EVENT_FOCUS = "focus";

@Component({
  components: {
    SignExamDataModal,
    RadioGroupField,
    Loader,
    Modal,
    Timer,
  },
})
export default class ExamProcessing extends Vue {
  public readonly DELTA = 1;
  public currentTaskId = DEFAULT_VALUE;
  public currentAnswerValue = DEFAULT_VALUE;
  public timeLeft = DEFAULT_VALUE;
  public loadingSetAnswer = false;
  public isExamTimeUp = false;
  public showFinishExamModal = false;
  public showEdsModal = false;

  @processingExam.State examData!: ExamType | ObjectOf<any>;
  @processingExam.State loading!: boolean;

  get getCurrentQuestion(): ExamQuestionType<SelectOptionType> | undefined {
    if (!this.examData || !this.examData.questions) {
      return undefined;
    }

    const question = this.examData.questions.find(
      (question: ExamQuestionType<ExamAnswerType>) =>
        question.id === this.currentTaskId
    );

    if (question === undefined) {
      return question;
    }

    this.setDefaultAnswerValue(question.id);

    const answers = question.answers.map((el: ExamAnswerType) => ({
      id: el.id,
      value: el.id,
      text: el.title,
    }));

    return { ...question, answers };
  }

  get getCurrentTaskIndex(): number {
    if (!this.examData || !this.examData.questions) {
      return DEFAULT_VALUE;
    }

    return this.examData.questions.findIndex(
      (question: ExamQuestionType<ExamAnswerType>) =>
        question.id === this.currentTaskId
    );
  }

  @Watch("$route")
  private onRoute(to: Route, from: Route): void {
    if (JSON.stringify(to.query) !== JSON.stringify(from.query)) {
      const { task }: { task?: string } = to.query;
      if (task === undefined) {
        return;
      }

      this.currentTaskId = parseInt(task) || this.currentTaskId;
    }
  }

  async mounted(): Promise<void> {
    const { task }: { task?: string } = this.$route.query;

    if (task) {
      this.currentTaskId = parseInt(task);
    } else {
      const [firstQuestion] = this.examData.questions;
      this.currentTaskId = firstQuestion.id;
    }

    window.addEventListener(EVENT_FOCUS, this.onFocusWindowHandler);
  }

  destroyed(): void {
    this.clearExamData();
    window.removeEventListener(EVENT_FOCUS, this.onFocusWindowHandler);
  }

  @userManager.Action getUserInfo!: () => Promise<void>;
  @processingExam.Mutation clearExamData!: () => void;
  @processingExam.Action finishExam!: (id: number) => Promise<void>;
  @processingExam.Action sendSign!: (params: {
    id: number;
    sign: string;
  }) => Promise<SignResultType>;
  @processingExam.Action getExamSignInfo!: (
    id: number
  ) => Promise<SignInfoType>;
  @processingExam.Action setExamAnswer!: (
    params: AnswerValueType
  ) => Promise<void>;

  public toggleTask(taskId: number): void {
    const { task } = this.$route.query;
    if (parseInt(task as string) === taskId) {
      return;
    }

    this.currentTaskId = taskId;

    this.$router.push({
      path: this.$route.path,
      query: {
        task: taskId.toString(),
      },
    });
  }

  public toggleVisibleFinishModal(): void {
    this.showFinishExamModal = !this.showFinishExamModal;
  }

  public async sendExamAnswer(value: number): Promise<void> {
    this.loadingSetAnswer = true;
    this.currentAnswerValue = value;

    const questionIsPassed = this.examData.answered_questions.find(
      (el: AnswerValueType) =>
        el.answer_id === value && el.question_id === this.getCurrentQuestion?.id
    );

    if (!questionIsPassed) {
      await this.setExamAnswer({
        answer_id: this.currentAnswerValue,
        question_id: this.getCurrentQuestion?.id || 0,
      });
    }

    this.loadingSetAnswer = false;
  }

  public async nextAnswer(): Promise<void> {
    const nextQuestionIndex = this.getCurrentTaskIndex + this.DELTA;
    const nextQuestion = this.examData.questions[nextQuestionIndex];

    if (nextQuestion !== undefined) {
      this.toggleTask(nextQuestion.id);
    }
  }

  public async goToFinishExam(): Promise<void> {
    try {
      if (this.examData.status === STATUS_IN_PROGRESS) {
        const { id } = this.examData;
        await this.finishExam(id);
      }
    } catch {
      await this.$router.push(app_routes.user_home.path);
    }

    this.showEdsModal = true;
    this.showFinishExamModal = false;
  }

  public checkTimeout(timeLeft: number): void {
    this.timeLeft = timeLeft;
    this.isExamTimeUp = DEFAULT_VALUE >= (timeLeft || 0);
  }

  private setDefaultAnswerValue(questionId: number): void {
    const answeredQuestion = this.examData.answered_questions.find(
      (el: AnswerValueType) => el.question_id === questionId
    );

    if (answeredQuestion === undefined) {
      this.currentAnswerValue = DEFAULT_VALUE;
    } else {
      this.currentAnswerValue = answeredQuestion.answer_id;
    }
  }

  private onFocusWindowHandler(): void {
    this.checkTimeout(this.timeLeft);
  }
}
